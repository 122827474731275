@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
   font-size: 12px;
   line-height: 1.428;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, San Francisco,
    Segoe UI, Roboto, Helvetica Neue, sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: var(--bs-light) !important;
  font-weight: 500;
  overflow-x: hidden;
}

.table-sm th {
  padding: 0.1rem;
}

fieldset {
  border: 1px solid #ddd !important;
  margin: 0.5em 0 1em 0 !important;
  min-width: 0;
  padding: 0.5em 0.5em !important;
  display: block;
  position: relative;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding-left: 10px !important;
}

legend {
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 0px;
  width: auto !important;
  color: #192a56 !important;
  border: 1px solid #ddd;
  border-radius: 4px !important;
  padding: 5px 10px !important;
  background-color: #ffffff;
}

.react-tel-input .form-control {
  font-size: 14px !important;
  width: 100% !important;
  border-radius: 0px;
}


.custom-switch {
  padding-left: 2.3em !important;
}

.text-xs {
  font-size: 10px !important;
}

.text-sm {
  font-size: 0.855rem !important;
}

.text-md {
  font-size: 12px !important;
  font-weight: 500;
}

.text-lg {
  font-size: 14px !important;
  font-weight: 500;
}

.text-xlg {
  font-size: 19px !important;
  font-weight: 500;
}

.course-table td {
  font-size: 12px;
}

.course-table tbody th {
  font-size: 12px;
}

.course-table tbody tr th {
  background: #a4b0be !important;
  border: none !important;
}

.form-control-md {
  padding: 0.58em !important;
}

.auth .auth_left .card, .auth .auth_left .ant-card{
  right: -150px;
  z-index: 99999;
}

.otpInput {
  font-size: 3rem !important;
  width: 5rem !important;
  height: 5rem;
  margin: 0 1.5rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.ant-modal-content {
  padding: 0px !important;
}

.ant-modal-body {
  padding: 20px 24px !important;
}

.ant-modal-header {
  padding: 15px 20px !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.ant-modal-footer {
  padding: 15px 20px !important;
  border-top: 1px solid #dee2e6 !important;
}

:where(.css-dev-only-do-not-override-87sbui).ant-space-compact-block {
  display: inline-block !important;
  width: auto !important;
}

.ant-tree-node-selected {
  font-weight: bold !important;
  color: var(--bs-danger) !important;
}
.auth {
  display: flex;
  height: 100vh;
  align-items: center;
}

.auth .auth_left {
  background: #fff;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 400px;
  padding: 0 20px;
}

.auth .auth_right {
  width: calc(100% - 400px);
  text-align: center;
}

@media screen and (max-width: 767px) {
  .auth .auth_right {
    display: none;
  }
}

.row-deck > .col,
.row-deck > [class*='col-'] {
  display: flex;
  align-items: stretch;
}

.row-deck > .col .card,
.row-deck > [class*='col-'] .card {
  flex: 1 1 auto;
}

.card-header {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(198, 190, 207);
  background: rgb(250, 249, 251);
  line-height: 1;
  position: relative;
  padding: 16px;
}

.card-options {
  color: #9aa0ac;
  margin-left: auto;
  display: flex;
  order: 100;
  align-self: center;
}

.ant-layout .ant-layout-header {
  line-height: normal !important;
  height: auto !important;
  padding: 10px 0px !important;
}

.ant-tabs-tab {
  padding: 7px 10px !important;
}

.ant-tabs-tab-btn {
  font-size: 10px !important;
}

.fw-normal {
  font-weight: 400 !important;
}