$danger: #bd3128;
$primary: #10ac84;
$dark: #2c3a47;
$info: #227093;
$warning: #f5cd79;
$gray: #dfe6e9;
$success: #218c74;
$white: #fff;
$light: #edf2f7;

$theme-colors: (
  'danger': $danger,
  'primary': $primary,
  'dark': $dark,
  'info': $info,
  'warning': $warning,
  'gray': $gray,
  'success': $success,
  'white': $white,
  'light': $light,
);

$enable-caret: true;
$enable-rounded: true;
$spacer: 1rem;
$font-size: 1.75em;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
